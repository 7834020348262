// Sections
interface Section {
  id: number
  name: string
  clientId: number
  order: number
  membershipGroups?: MembershipGroup[]
  informerListCards?: Card[]
  cards?: Card[]
}

// Cards
interface Card {
  id: number
  name: string
  iconStorageObject?: {
    id?: number
    downloadUrl?: string
  }
  url: string
  order?: number | null
  clientId: number
  isAll: boolean
  isHidden: boolean
  members?: Membership[]
  membershipGroups?: MembershipGroup[]
}

// Membership Group
interface MembershipGroup {
  id: number
  membershipGroupId: number
  membershipGroup?: {
    name?: string
  }
}

// Membership
interface Membership {
  id?: number
  informerListCardId?: number
  membershipId?: number
}

/**
 * Returns filtered list of Sections with Cards, based on given filter options ("Just Me", Selected Membership Groups, Text Field Input Search)
 * @param activeUserMembershipGroupIds  - Array of membershipGroup IDs that the current user has access to
 * @param activeUserMembershipId        - Current user membership ID
 * @param sections                      - Array of sections to filter through
 * @param searchVal                     - String of text from the input field
 * @param searchGroups                  - Array of membershipGroup IDs from the dropdown input options
 * @returns Array of Sections
 */
export const compileSectionsCards = (
  activeUserMembershipId: number,
  sections: Section[],
  searchVal: string,
  searchGroups: number[],
) => {
  // Format the data to match UI components and template
  const allSectionsFormatted: Section[] = formatSectionsAndCards(sections)

  // Does the groups filter include "Just Me"?
  const searchIncludesJustMe: boolean = searchGroups.includes(0)

  // Does the groups filter include any other selected options?
  const searchIncludesMembershipGroups: boolean = searchGroups.some(
    (groupId) => groupId !== 0,
  )

  // Does the search text field have a value?
  const searchIncludesTextInputString: boolean = searchVal !== ''

  // If there are no other active filters, return all items by user access
  if (
    !searchIncludesJustMe &&
    !searchIncludesMembershipGroups &&
    !searchIncludesTextInputString
  ) {
    return allSectionsFormatted
  }

  // Filter Items By Search Term
  const sectionsAndCardsFilteredBySearchTerm = searchIncludesTextInputString
    ? filterHomeSectionsAndCardsBySearchTerm(allSectionsFormatted, searchVal)
    : allSectionsFormatted

  // Reduce the sections and return those that have matching cards
  const sectionsAndCardsFilteredBySelectValues =
    searchIncludesJustMe || searchIncludesMembershipGroups
      ? sectionsAndCardsFilteredBySearchTerm.reduce(
          (filteredSections, currentSection) => {
            // Reduce the cards and return those that match the search filters
            const cardsFilteredBySearchValues = currentSection?.cards.reduce(
              (filteredCards, currentCard) => {
                // IF the filter is JUST ME
                const cardIsAssignedToCurrentUser =
                  searchIncludesJustMe &&
                  currentCard?.members.some((member) => {
                    return member.membershipId === activeUserMembershipId
                  })

                // Check if card is assigned to membership group user is assigned to
                const cardHasMembershipGroupFromSelectedUserAttached =
                  searchIncludesMembershipGroups &&
                  currentCard?.membershipGroups.some((membershipGroup) => {
                    return searchGroups.includes(
                      membershipGroup.membershipGroupId,
                    )
                  })

                // If the card matches any of the filters - SHOW IT
                if (
                  cardIsAssignedToCurrentUser ||
                  cardHasMembershipGroupFromSelectedUserAttached
                ) {
                  filteredCards.push(currentCard)
                }

                return filteredCards
              },
              [],
            )

            // If any cards were matching, display the section
            if (cardsFilteredBySearchValues.length > 0) {
              filteredSections.push({
                ...currentSection,
                cards: cardsFilteredBySearchValues,
              })
            }
            return filteredSections
          },
          [],
        )
      : sectionsAndCardsFilteredBySearchTerm

  return sectionsAndCardsFilteredBySelectValues
}

/**
 * Filter the Sections and Cards to show global sections/cards, show membership group sections/cards, and active user assigned cards
 * @param sections - Array of sections to filter through
 * @returns Array of Sections
 */
const formatSectionsAndCards = (sections: Section[]) => {
  // Format data to usable structure for the front-end
  // Remove any sections that have no cards attached
  const formattedSections = sections.reduce(
    (filteredSectionsByCardCount, currentSection) => {
      // IF there are cards, push to the output array
      if (currentSection?.informerListCards.length) {
        filteredSectionsByCardCount.push({
          ...currentSection,
          cards: currentSection?.informerListCards,
        })
      }

      return filteredSectionsByCardCount
    },
    [],
  )

  return formattedSections
}

/**
 * Returns filtered list of Sections with Cards, based on text input from the search field
 * @param sections                  - Array of sections to filter through
 * @param textInputFromSearchField  - String of text from the input field
 * @returns Array of Sections
 */
const filterHomeSectionsAndCardsBySearchTerm = (
  sections: Section[],
  textInputFromSearchField: string,
) => {
  const sectionsWithCardsThatMatchSearchTerm = sections.reduce(
    (matchingSections, currentSection) => {
      const cardsMatchingSearchTerm = currentSection?.cards.filter((card) => {
        return card.name
          .toLowerCase()
          .includes(textInputFromSearchField.toLowerCase())
      })

      cardsMatchingSearchTerm.length &&
        matchingSections.push({
          ...currentSection,
          cards: cardsMatchingSearchTerm,
        })

      return matchingSections
    },
    [],
  )

  return sectionsWithCardsThatMatchSearchTerm
}
