import { FC } from 'react'

import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'
import { useAuth } from 'src/Providers'

import GlobalSearch from '../GlobalSearch/GlobalSearch'



interface Props {
  isMobile: boolean
}
const InformerHeader: FC<Props> = ({
  isMobile,
}) => {
  const [globalSearchModernEnabled] = useLiveFeature('globalSearchModern')

  const {currentUser} = useAuth()

  const { headerUrl, headerType, logoUrl, name } = currentUser.parentData

  return (
    <>
      <div className=" bg-white relative overflow-visible shadow flex-auto z-10 flex justify-center items-center">
        {headerType === 'VIDEO' && (
          <video
            autoPlay
            loop
            muted
            playsInline
            className="z-10 absolute w-full shadow h-full object-cover"
          >
            <source src={headerUrl} type="video/mp4" />
            Your browser does not support the video tag, Chrome/Safari/Firefox
            are supported.
          </video>
        )}
        <div
          className="px-4 py-5 sm:p-6 z-30 md:py-16 min-w-full bg-gradient-to-r from-indigo-500 to-indigo-600 bg-cover bg-center bg-no-repeat flex justify-center items-center h-full"
          style={headerUrl ? { backgroundImage: `url(${headerUrl})` } : {}}
        >
          <div className="flex flex-col place-items-center space-y-12 z-20 w-full">
            {logoUrl && (
              <img
                className={`${isMobile ? 'max-h-36 max-w-36' : 'h-36'}`}
                alt="Logo"
                src={logoUrl}
              />
            )}
            {!logoUrl && <p className="text-[40px] text-white ">{name}</p>}
            {!globalSearchModernEnabled && (
              <GlobalSearch isMobile={isMobile} isHomePage />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default InformerHeader
