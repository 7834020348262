// TODO: add org support
// TODO: add membershipGroup support
// TODO: add SL injection support

import { FC } from 'react'

import {
  FindAnnouncerBarQuery,
  FindAnnouncerBarQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import AnnouncerBar from 'src/components/Announcements/AnnouncerBar/AnnouncerBar'

export const QUERY = gql`
  query FindAnnouncerBarQuery {
    announcerEvents: announcerEventsByGroups {
      id
      clientId
      isActive
      eventBody
      eventLink
      eventButtonText
      startAt
      endAt
    }
  }
`

type CellProps = CellSuccessProps<
  FindAnnouncerBarQuery,
  FindAnnouncerBarQueryVariables
>

export type Announcement = CellProps['announcerEvents'][0]
export const Loading = () => null

export const Empty: FC<CellProps> = () => <AnnouncerBar events={[]} />

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: FC<CellProps> = ({ announcerEvents }) => {
  return <AnnouncerBar events={announcerEvents} />
}
