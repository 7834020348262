import { FC, ReactNode } from 'react'

import { useMediaQuery } from 'usehooks-ts'

interface InformerMasterHeaderProps {
  children?: ReactNode
}

const InformerMasterHeader: FC<InformerMasterHeaderProps> = ({ children }) => {
  // Check if mobile
  const isMobile = useMediaQuery('(max-width: 769px)')

  return (
    <>
      {children && (
        <>
          <p className="text-lg mb-2 text-gray-500">Recent Items</p>
          <div
            className={`flex flex-row flex-wrap gap-4 ${
              isMobile
                ? 'w-full flex flex-col'
                : 'items-start justify-between border rounded-md border-gray-200 bg-gray-100 p-3'
            }`}
          >
            <div className="w-full grow order-1">{children}</div>
          </div>
        </>
      )}
    </>
  )
}

export default InformerMasterHeader
